.menu-profile {
  padding: 16px;
  background: var(--Backgroud-Backgroud-1, #fff);
  min-height: 100vh;
  border-radius: 10px;

  &__info {
    display: flex;
    align-items: center;
    padding: 12px;
    &__image {
      img {
        width: 44px;
        height: 44px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &__title {
      h3 {
        margin: 0;
        color: var(--Text-colorcolor-Text-primaty, #192434);
        /* Title 2/M */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      span {
        color: var(--Text-colorcolor-Text-secondary, #626d7c);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
  &__frame {
    padding: 12px 0;

    &__widget {
      margin: 8px 0;

      &__title {
        color: var(--Text-colorcolor-Text-secondary, #626d7c);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        padding: 4px 12px;
        margin-bottom: 10px;
      }
      &__item {
        display: flex;
        align-items: flex-start;
        padding: 8px 12px;

        span {
          color: var(--Text-colorcolor-Text-secondary, #626d7c);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        &__icon {
          margin-right: 8px;

          &:hover {
            color: #00904a;
          }
        }
        &:hover {
          background: var(--primary-basic-10, rgba(0, 144, 74, 0.1));
          color: #00904a;
          border-radius: 4px;
          cursor: pointer;
        }
        &:active {
          background: var(--primary-basic-10, rgba(0, 144, 74, 0.1)) !important;
          color: #00904a !important;
          border-radius: 4px !important;
        }
      }
      &__item:active {
        background: var(--primary-basic-10, rgba(0, 144, 74, 0.1)) !important;
        color: #00904a !important;
        border-radius: 4px !important;
      }
    }
  }
}
