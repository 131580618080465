.ant-modal-content {
  padding: 0 !important;
}
.ant-modal-header {
  padding: 16px !important;
  border-bottom: 1px solid #dbdfe5 !important;
  margin: 0 !important;
}
.ant-modal-title {
  text-align: center;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
}

.ant-modal-body {
  padding: 16px !important;
}
.ant-modal-footer {
  padding: 16px !important;
  border-top: 1px solid #dbdfe5 !important;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.time_row .ant-typography:nth-child(1) {
  margin: 0 !important;
  font-size: 18px !important;
}
.time_row .ant-typography:nth-child(2) {
  margin: 0 !important;
}
.time_row .ant-typography:nth-child(2) > span {
  color: var(--Text-colorcolor-Text-secondary, #626d7c) !important;
  text-align: center;
  font-family: 'Inter Tight';
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0px;
}
.ant-input {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.product-quantity {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 8px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.input-group_pre {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group_next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  align-content: center;
}

.input-number {
  width: 60px;
  text-align: center;
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
  height: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.quantity-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0;
}

.stock {
  color: var(--Text-colorcolor-Text-secondary, #626d7c);
  /* Body/R */
  font-family: 'Inter Tight';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}
.customer-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.customer-item {
  display: flex;
  align-items: center;
}

.customer-avatar {
  border-radius: 50%;
}

.customer-info {
  display: flex;
  flex-direction: column;
}

.customer-name {
  font-weight: bold;
}

.customer-phone {
  font-size: 12px;
  color: #888;
}


.last-minute-bid {
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.product-title {
  color: #192434;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 0px;
}

.auction-time .time-block {
  display: flex;
  align-items: center;
}

.auction-time .time-separator {
  margin: 0 4px;
  color: #626d7c;
}

.price-section {
  margin-bottom: 16px;
}

.last-minute-input .ant-input {
  border-radius: 4px;
}

.price-note {
  font-size: 12px;
  color: #626d7c;
}