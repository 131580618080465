.ant-radio-wrapper {
  .ant-radio-checked .ant-radio-inner {
    background-color: #00904a;
    border-color: #00904a;
  }
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00904a;
  border-color: #00904a;
}
