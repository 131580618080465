/* src/index.css */
body {
  background-color: #f0f2f5;
  width: 100%;
}

.procduct_relative {
  border-radius: 12px;
  background-color: #ffffff;
  padding: 16px 16px;
  margin-bottom: 24px;
}
.procduct_relative_row {
  padding: 16px 0;
}
.ant-typography .product_relative_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}
.ant-card-meta-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-card-meta-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-card-meta-description .ant-typography {
  color: #d32f2f; /* Red color for the price */
}

.ant-layout-header {
  background-color: #001529;
  text-align: center;
  font-size: 20px;
  line-height: 64px; /* Align text vertically */
}

.ant-btn-link {
  color: #00904a !important;
  font-weight: bold;
}
.ant-card {
  margin: auto; /* Center card content */
}

.ant-card-cover img {
  width: 100%; /* Ensure images fit within their container */
}

.product-card {
  width: 240px; /* Điều chỉnh kích thước nếu cần */
  margin: 10px;
}

.card-content {
  padding: 12px; /* Khoảng cách bên trong thẻ */
}

.time {
  font-size: 14px;
  color: gray;
  display: flex;
  align-items: center;
}
.time_clock {
  padding: 0 6px 0 0;
}
.time_value {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.product-title {
  display: block;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.price {
  font-size: 16px;
  color: #d32f2f;
  font-weight: bold;
}

.old-price {
  font-size: 12px;
  text-decoration: line-through;
  color: #666;
  margin-top: 4px;
}

.favorite-button {
  margin-left: 10px; /* Khoảng cách giữa giá và nút yêu thích */
  padding: 0;
  color: #0c0a0a; /* Màu biểu tượng yêu thích */
}
