.custom-table .ant-modal-body {
  padding: 16px !important;
}

.custom-table .ant-table-thead > tr > th {
  color: #626d7c;
  font-weight: 500;
  font-size: 14px;
}

.custom-table .ant-table-tbody > tr > td {
  font-weight: 500;
  font-size: 14px;
  padding: 8px 16px;
}

.custom-table .ant-table-bordered .ant-table-container {
  border: transparent;
}

.custom-table .ant-table {
  border-radius: 4px;
}

.custom-table .ant-table-pagination {
  margin-right: 16px;
}
