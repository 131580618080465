.profile-user-change-password {
  padding: 24px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--Backgroud-Backgroud-1, #fff);
  min-height: 100vh;
  border-left: 1px solid var(--Stoke-Stoke, #dbdfe5);

  &__title {
    color: var(--Text-colorcolor-Text-primaty, #192434);
    /* Heading 6/B */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
  }
  &__content {
    margin: 24px 0;

    &__top {
      border-bottom: 1px solid var(--Stoke-Stoke, #dbdfe5);
      form {
        button {
          border-radius: 4px;
          background: var(--Backgroud-Backgroud-button, #e8ebef);

          &:hover {
            background: var(--primary-basic-10, #00904a);
          }
        }
      }
    }

    &__bot {
      &__title {
        h2 {
          color: var(--Text-colorcolor-Text-primaty, #192434);
          /* Heading 6/B */
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 140% */
        }
        span {
          color: var(--Text-colorcolor-Text-secondary, #626d7c);
          /* Body/R */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
      &__btn {
        display: inline-block;
        margin-top: 24px;
        padding: 8px 12px;
        border-radius: 4px;
        background: var(--Secondary-error, #f82859);
        color: #fff;

        &:hover {
          cursor: pointer;
          background: var(--primary-basic-10, #00904a);
        }
      }
    }
  }
}
