.profile-user-address {
  padding: 24px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--Backgroud-Backgroud-1, #fff);
  min-height: 100vh;
  border-left: 1px solid var(--Stoke-Stoke, #dbdfe5);

  &__title {
    color: var(--Text-colorcolor-Text-primaty, #192434);
    /* Heading 6/B */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
  }
  &__table {
    .ant-table-thead {
      .ant-table-cell {
        color: var(--Text-colorcolor-Text-secondary, #626d7c);
        /* CAPTION/M */
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        text-transform: uppercase;
        border-right: none !important;
        border-top: none !important;
      }
      td {
        border: none !important;
      }
    }
  }
}
