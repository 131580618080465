.MuiBox-root {
  /* background-color: #f4f5f7;  */
}
/* Ensure the container covers the full width and height */
/* .product_auction_container {
  background-color: #fafafa;
  padding: 16px;
  margin: 0 auto;
  width: 100%;
}
.product_auction_container .css-m2yoay {
  background-color: #fafafa;
 
} */

/* Adjust the Row and Col components if necessary */
.product_auction_container .ant-row {
  margin-left: 0 !important; /* Remove unnecessary margins */
  margin-right: 0 !important;
}

/* If you have a specific background for the center column */
.product_auction_container .ant-col-md-12 {
  background-color: #ffffff; /* Center background color, if needed */
  padding: 24px;
}

/* Ensure that left and right sections are transparent or match the container background */
.product_auction_container .ant-col-md-8,
.product_auction_container .ant-col-md-4 {
  background-color: transparent; /* Match the container background color */
  padding: 0;
}
.auction_customer_top_row_left_share {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Backgroud-Backgroud-1, #fff);
}

.auction_customer_top_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.auction_customer_top_row h2 {
  color: var(--Text-colorcolor-Text-primaty, #192434);
  font-family: 'Inter Tight';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.ant-spin-dot-item {
  background-color: #00904A !important;
}
