
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 45%;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
  justify-content: center;
  align-items: center;
}
.image-gallery-image {
  border: 1px solid var(--Stoke-Stoke, #DBDFE5);
  aspect-ratio: 1 / 1;
  width: 100%; 
  object-fit: cover;
  object-position: center; 
  background: lightgray;
}
.image-gallery-thumbnail-image {
  border: 1px solid var(--Stoke-Stoke, #DBDFE5);
  aspect-ratio: 1 / 1;
  width: 100%; 
  object-fit: cover;
  object-position: center; 
  background: lightgray;
}
