.profile-user-wallet {
  padding: 24px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--Backgroud-Backgroud-1, #fff);
  min-height: 100vh;
  border-left: 1px solid var(--Stoke-Stoke, #dbdfe5);

  &__title {
    color: var(--Text-colorcolor-Text-primaty, #192434);
    /* Heading 6/B */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
  }
  &__content {
    margin: 24px 0;

    &__card {
      margin-top: 16px;
    }
    &__transaction {
      margin-top: 24px;
      border-top: 1px solid var(--Stoke-Stoke, #dbdfe5);
      &__title {
        color: var(--Text-colorcolor-Text-primaty, #192434);
        /* Heading 6/B */
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 140% */
        margin-bottom: 8px;
      }

      &__tab {
        padding-left: 0 !important;
        .css-10ozstp-MuiButtonBase-root-MuiTab-root {
          padding: 8px 0 !important;
          margin-right: 8px;
        }
        .css-19kzrtu {
          padding-left: 0 !important;
        }
      }
    }
  }
}
