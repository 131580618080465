.delete-modal {
    .delete-icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .delete-icon {
      width: 80px; /* Adjust the size of your SVG icon */
      height: auto;
      margin-bottom: 16px;
    }
  }
  