.record-item-custom {
  padding: 0 10px;
}

.product-shopping {
  transition: .2s ease-in-out;
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  padding: 0 10px 22px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
  filter: drop-shadow(0 4px 20px rgba(0,0,0,.05));
}

.product-shopping:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.product-shopping .card-prod {
  padding: 5px 0 0;
  background-color: #fff;
  height: 100%;
  text-align: left;
}

.thumbnail {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
  padding: 0;
  border: none;
  height: 186px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.icon-brand {
  width: 30px;
  height: 30px;
  padding: 6px;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 10px;
  background: #fff;
  z-index: 9;
}

.prod-image {
  position: absolute;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.num-bids {
  position: absolute;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  bottom: 10px;
  left: 10px;
  background: #fff;
  border-radius: 30px;
  padding: 0 10px;
}

.group-prod-name {
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 12px;
  color: #000;
}

.prod-name {
  max-height: 36px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 18px;
}

.group-prod-price {
  color: #646464;
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.bid-price {
  display: block;
  justify-content: space-between;
  min-width: 50%;
}

.price-vn {
  color: #ffa902;
  font-size: 13px;
}

.price-vn span {
  width: 100%;
  color: #c22026;
  font-size: 16px;
  line-height: 21px;
}

.fm-extra-bold {
}

.group-action {
  line-height: 30px;
  display: flex;
  align-items: center;
}

.heart {
  margin-left: auto;
}

.time-down {
  display: inline;
}

.product-shopping .card-prod .thumbnail .prod-image img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
  border-style: none;
  transition: all .7s;
}

.product-shopping .card-prod .thumbnail .num-bids img {
  width: 16px;
  vertical-align: middle;
  display: inline;
}
