.product-name {
  display: -webkit-box;
  max-height: 66px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  margin-top: 0;
  margin-bottom: 0;
  color: #292b2e;
  padding: 0 0 24px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin: 0;
  margin-bottom: 16px;
}

/* src/components/AuctionTimer.css */
.auction-timer {
  background-color: #f4f5f7; /* Match the background color */
  padding: 16px;
  border-radius: 8px; /* Rounded corners */
  align-self: stretch;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}

.auction-timer .ant-typography {
  text-align: center;
  margin: 0;
  font-size: 14px; /* Adjust font size */
}

.auction-timer .ant-typography strong {
  font-size: 18px; /* Adjust font size for numbers */
  color: #1d1d1f;
  font-weight: 600; /* Dark color for text */
  text-align: center;
}

.auction-timer .ant-btn-link {
  font-size: 14px;
  color: #00b32d; /* Green color for the link */
  padding: 0;
}

.auction-timer .ant-btn-link:hover {
  color: #009900; /* Darker green on hover */
}
.auction_right_top_title {
  font-weight: 400;
  margin-bottom: 5px;
}

.border-dashed {
  border: dashed #797979;
  border-width: 0 0 1px;
  height: 0;
}

.ant-input-number-input-wrap {
  font-weight: bold;
  font-size: 18px;
}

.ant-input-number-input {
  text-align: right !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.card-header {
  margin-bottom: 20px;
}

.product-auction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(267.84deg, #e4c41d -6.96%, #c22 300.88%);
  color: #fff;
  font-size: 15px;
  margin-bottom: 16px;
}
.product-body {
}
.product-body_price {
  padding: 24px 0;
}
.gutter-row {
  margin-bottom: 8px;
}

.bold {
  font-weight: 600;
  vertical-align: bottom;
  margin-bottom: 4px;
  color: var(--Text-colorcolor-Text-secondary, #626d7c);
  /* Body/M */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.value {
  color: var(--Text-colorcolor-Text-primaty, #192434);
  /* Body/R */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.product-options {
  padding: 24px;
  background-color: #fff;
}
.product-option_containe {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Khoảng cách giữa các hàng */
  font-size: 14px;
  font-weight: 400;
}

.row {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
}

.label-price {
  width: 100%;
  font-size: 14px;
  margin-bottom: 5px;
}

.amount-price {
  font-size: 18px;
  line-height: 18px;
  color: #192434;
}

.price-vi {
  font-size: 28px;
  line-height: 31px;
  color: #c22026;
  margin-right: 10px;
}

.price-jp {
  font-size: 16px;
  line-height: 21px;
  color: #6c6c6c;
}

.price-tax {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.meta-info {
  color: #595959;
  margin-bottom: 14px;
  font-size: 14px;
}

.line-item {
  display: flex;
  margin-bottom: 5px;
}

.label {
  margin-right: 5px;
}

.line-item {
  display: flex;
  margin-bottom: 5px;
}

.product-fee-transaction {
  margin-bottom: 15px;
}

.product-group-select {
  margin-right: 28px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #292b2e;
  margin-bottom: 10px;
}

.bid-now {
  background: #00904a;
  border-color: #00904a;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 36px;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
}

.bid-now:hover {
  background: #00904a !important;
  border-color: #00904a !important;
  color: rgb(255, 255, 255) !important;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

.bid-last {
  background: rgb(255, 255, 255);
  border-color: #00904a;
  color: #00904a;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 20px;
  justify-content: center;
}
.bid-last:hover {
  background: #00904a !important;
  border-color: #00904a !important;
  color: rgb(255, 255, 255) !important;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

.bid-snipe {
  background: #e8ebef;
  border-color: #e8ebef;
  color: #192434;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 20px;
  justify-content: center;
}

.bid-last-minute {
  background: #fff;
  border-color: #00904a;
  color: #00904a;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 20px;
  justify-content: center;
}

.bid-snipe:hover {
  background: #00904a !important;
  border-color: #00904a !important;
  color: rgb(255, 255, 255) !important;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

.dpm-sub {
  margin: 15px 0;
  font-size: 14px;
}

.link-origi {
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.link-origi_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-link {
  font-size: 14px;
  font-weight: 500;
  color: #00904a;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  margin-right: 5px;
}

.seller-data {
  display: flex;
  align-items: center;
  margin: 0 0 16px;
}

.seller-avatar {
  flex-basis: 60px;
  height: 60px;
  flex-shrink: 0;
  position: relative;
}

.seller-person {
  margin: 0 15px;
  flex-grow: 1;
}

.seller-number-percentage {
  color: #687077;
}

.link-more {
  color: #f3800e;
}

.rate-detail {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.seller-ok,
.seller-ok:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #76c546 !important;
  color: #fff !important;
  border-radius: 8px;
  padding: 8px 10px;
  text-align: center;
  border: none !important;
}

.seller-avatar img {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.c-pointer {
  cursor: pointer;
}

.box-seller {
  margin: 16px 0;
  border-bottom: 1px solid #e0e0e0;
}
.box-support .bold {
  color: var(--Text-colorcolor-Text-secondary, #626d7c);
  /* Body/M */
  font-family: 'Inter Tight';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.box-support a {
  display: block;
  color: var(--Text-colorcolor-Text-primaty, #192434);
  /* Body/R */
  font-family: 'Inter Tight';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.product-option tr > td {
  border: none;
  font-size: 15px !important;
  padding: 0.5rem 0.5rem;
}

.product-option tr > td.bold {
  width: 40%;
}
.ProductExplanation {
  margin: 24px 0 24px 0;
  padding: 24px 24px;
  background-color: #ffff !important;
  border-radius: 12px;
}
.ProductExplanation__header {
}

.ProductExplanation__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.ProductExplanation_bot {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProductExplanation_bot_btn {
  text-align: center;
  color: var(--Primary-Basic, #00904a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: block;
  align-content: center;
  cursor: pointer;
  padding: 8px 12px;
}
.ProductExplanation_bot_icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px; /* Adjust icon size if needed */
  margin-left: 5px;
}

.star {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  line-height: 10px;
}

.star2 {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  line-height: 10px;
}

.star:before {
  display: inline-block;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgOC41MzY4NEw4LjA5IDEwLjVMNy4yNyA2LjhMMTAgNC4zMTA1M0w2LjQwNSAzLjk4OTQ3TDUgMC41TDMuNTk1IDMuOTg5NDdMMCA0LjMxMDUzTDIuNzMgNi44TDEuOTEgMTAuNUw1IDguNTM2ODRaIiBmaWxsPSIjODc4Njg1Ii8+Cjwvc3ZnPgo=')
    left center / 10px no-repeat;
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
}

.star2:after {
  width: 10px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgOC41MzY4NEw4LjA5IDEwLjVMNy4yNyA2LjhMMTAgNC4zMTA1M0w2LjQwNSAzLjk4OTQ3TDUgMC41TDMuNTk1IDMuOTg5NDdMMCA0LjMxMDUzTDIuNzMgNi44TDEuOTEgMTAuNUw1IDguNTM2ODRaIiBmaWxsPSIjRkZEQTQ1Ii8+Cjwvc3ZnPgo=')
    left center / 10px no-repeat;
  content: '';
  position: absolute;
  height: 10px;
  line-height: 10px;
}
.favorite-sellter {
  background-color: #687077;
}
.favorite-sellter_btn {
  border-radius: 50%;
  color: #626d7c;
  text-align: center;
}

.product-card {
  width: 240px; /* Điều chỉnh kích thước nếu cần */
  margin: 10px;
}

.card-content {
  padding: 16px; /* Khoảng cách bên trong thẻ */
}

.time {
  font-size: 12px;
  color: gray;
  margin-bottom: 8px;
}

.product-title {
  display: block;
  margin-bottom: 8px;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.price {
  font-size: 16px;
  color: #d32f2f;
  font-weight: bold;
}

.old-price {
  font-size: 12px;
  text-decoration: line-through;
  color: #666;
  margin-top: 4px;
}

.favorite-button {
  margin-left: 10px; /* Khoảng cách giữa giá và nút yêu thích */
  padding: 0;
  color: #d32f2f; /* Màu biểu tượng yêu thích */
}
.product_auction_container {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
}
.product_auction_container_row {
  display: flex;
  align-items: flex-start; /* Aligns items at the start of the container */
  justify-content: space-between; /* Space between left and right sections */
  border-bottom: 1px solid var(--Stoke-Stoke, #dbdfe5);
}
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to start horizontally */
  padding-bottom: 24px;
}
.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align contents */
}
.left_bot_section {
  padding-top: 16px;
}
.left_bot_section_row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left_bot_section_share {
  padding-right: 16px;
  display: flex;
  align-items: center;
  border-right: 1px solid #dbdfe5;
}
.left_bot_section_stroke {
  stroke-width: 1px;
  stroke: var(--Stoke-Stoke, #dbdfe5);
}
.left_bot_section_favorite {
  padding-left: 16px;
  display: flex;
  align-items: center;
}
.left_bot_section_share_icon {
  display: inline-block;
  font-size: 14px; /* Adjust icon size if needed */
}
.left_bot_section_favorite_icon {
  display: inline-block;
  font-size: 14px; /* Adjust icon size if needed */
}
.left-section img {
  /* Ensures the image is responsive */
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.middle-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
}
.grid-container > .ant-col:first-child {
  padding-left: 0 !important;
}

.grid-container > .ant-col:last-child {
  padding-right: 0 !important;
}
