.ProductExplanation__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProductExplanation__title {
  font-size: 16px;
  color: var(--Text-colorcolor-Text-primaty, #192434);
font-family: "Inter Tight";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 140% */
}
.ProductExplanation__commentArea {
  padding: 16px 0;
}
.ProductExplanation__icon {
  display: flex;
  align-items: center;
  cursor: pointer; /* Thêm hiệu ứng khi hover nếu cần */
  padding: 12px;
  border-radius: 4px;
  background: var(--Backgroud-Backgroud-button, #E8EBEF);
}

.ProductExplanation__icon svg {
  width: 20px;
  height: 20px;
  fill: #555; /* Màu sắc icon */
}

.ProductExplanation__icon:hover svg {
  fill: #000; /* Màu khi hover */
}
.ProductExplanation__icon {
  display: flex;
  align-items: center;
  gap: 4px; /* Khoảng cách giữa icon và chữ */
  cursor: pointer; /* Con trỏ chuột khi hover */
}

.ProductExplanation__translateText {
  font-size: 14px; /* Kích thước chữ */
  color: #555; /* Màu chữ */
}

.ProductExplanation__icon:hover .ProductExplanation__translateText {
  color: #000; /* Màu chữ khi hover */
}
