.detail-customer-page{
    font-family: "Inter Tight", sans-serif;
    padding: 16px;
    .MuiTab-root{
        text-transform: inherit;
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter Tight", sans-serif;
        padding: 10px 6px;
    }
    .MuiBox-root{
        padding: 10px 0px;
    }
    .MuiBox-root{

    }
    .banner{
        position: relative;
        background-image: url("../../../../../../public/assets/images/bg_user_info.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 120px;
        width: 100%;
        margin-bottom: 70px;
        .avatar{
            height: 128px;
            width: 128px;
            left: 20px;
            top: 60px;
            border-radius: 50%;
            border: 2px solid #fff;
            position: absolute;
            img{
                width: 100%;
                border-radius: 50%;
                height: 100%;
            }
        }
        .info{
            position: absolute;
            bottom: -60px;
            left: 160px;
            .name{
                color: #192434;
                font-weight: 500;
                font-size: 20px;
            }
            .email{
                color: #626D7C;
                font-weight: 400;
                font-size: 12px;
            }
        }
        .group-button{
            position: absolute;
            bottom: -60px;
            right: 0px;
        }
    }
    .detail-customer-overview{
        .label{
            font-size: 14px;
            font-weight: 500;
            font-family: "Inter Tight", sans-serif;
            color: #626D7C;
        }
        .value{
            font-size: 14px;
            font-weight: 500;
            font-family: "Inter Tight", sans-serif;
            color: #192434;
        }
    }
}